<template>
    <div class="favorite-content">
        <div class="feature-video-sort">
            <div class="sort-name">类目榜单：</div>
            <div class="sort-list">
                <div class="item" :class="{current: currentSort === sortIndex}" v-for="(sortItem, sortIndex) in sortList" :key="sortIndex" @click="chooseSort(sortIndex)">{{sortItem}}</div>
            </div>
        </div>
        <el-table :data="videoCategoryList" class="favorite-table"  :header-cell-style="{fontWeight: 'normal',color: '#333'}">
            <el-table-column label="排行" align="center" min-width="40">
                <template slot-scope="scope" >
                    <div class="goods-sort">
                        <span class="sort">{{(scope.$index + 1 )+((favoritePages.currentPageNum-1)*favoritePages.eachPageNum)}}</span>
                        <div class="exponential-trend rise-red" v-show="scope.row.score > 0">
                            <i class="iconfont">&#xe737;</i>{{ Math.abs(scope.row.score) }}
                        </div>
                        <div class="exponential-trend decline-green" v-show="scope.row.score < -1">
                            <i class="iconfont">&#xe738;</i>{{ Math.abs(scope.row.score) }}
                        </div>
                        <div class="exponential-trend" v-show="scope.row.score === 0"></div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="商品" width="460">
                <template slot-scope="scope">
                    <div class="goods-detail"  @click="goodesDetail(scope.row.shop_url)">
                        <div class="goods-img">
                            <img :src="scope.row.image_url" alt="">
                        </div>
                        <div class="goods-info">
                            <span class="goods-link">{{ scope.row.title }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="关联视频数" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.videos_num }}</span>
                </template>
            </el-table-column>
            <el-table-column label="抖音浏览数" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.views_num }}</span>
                </template>
            </el-table-column>
            <el-table-column label="销量数" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.sales_num }}</span>
                </template>
            </el-table-column>
            <el-table-column label="售价" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.price }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="160" align="center">
                <template slot-scope="scope" >
                    <el-button type="danger" circle size="small" @click="favoritePlayVideo( scope.row.video_url)">
                        <i class="iconfont" >&#xe63a;</i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" background style="padding-bottom: 10px; margin-bottom: 10px"
                :current-page="favoritePages.currentPageNum"
                :page-size="favoritePages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="favoritePages.total"
                v-show="videoCategoryList.length > 0 "
                @current-change="favoriteCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "FavoriteModule",
        data() {
            return {
                chooseTime: '日榜',
                lastSevenDay: [],
                active_time: '',
                currentSort: 0,
                sortList: ['人气好物榜'],
                videoCategoryList: [],
                //分页
                favoritePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted(){
           this.getFavoriteList()
        },
        methods:{
            // 获取好物列表
            getFavoriteList(){
                let param = {
                    page: this.favoritePages.currentPageNum,
                    pageSize: this.favoritePages.eachPageNum,
                    paging: 1,
                };
                this.$httpStudent.axiosGetBy(this.$api.api_goodThing,param,res => {
                    if (res.code == 200) {
                        this.videoCategoryList = res.data.data;
                        this.favoritePages.total = res.data.total;
                    }else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                })
            },
            //类目榜单
            chooseSort(sortIndex) {
                this.currentSort = sortIndex
            },
            // 视频播放
            favoritePlayVideo(video_url){
                window.open(video_url)
            },
            // 商品详情
            goodesDetail(shop_url){
                window.open(shop_url)
            },
            //分页
            favoriteCurrentChange(val){
                this.favoritePages.currentPageNum = val;
                this.getFavoriteList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .favorite-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        .favorite-feature{
            display: flex;
            justify-content: space-between;
            margin: 0 20px 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #eaeaea;
            .feature-left{
                .el-select {
                    margin-left: 10px;
                }
            }
        }
        .feature-video-sort{
            display: flex;
            align-items: center;
            padding: 0 0 20px 20px;
            border-bottom: 1px solid #eaeaea;
            .sort-list {
                display: flex;
                .item {
                    padding: 0 10px;
                    height: 26px;
                    line-height: 26px;
                    color: #666;
                    cursor: pointer;
                    &.current,&:hover{
                        background: #2338E6;
                        color: #fff;
                    }
                }
            }
            .sort-name {
                /*line-height: 26px;*/
            }
        }
        .favorite-table{
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;
            ::v-deep.el-table__body-wrapper{
                min-height: 668px;
            }
            .goods-sort {
                font-size: 18px;
                font-weight: bold;
                color: #333;
                display: flex;
                align-items: baseline;
                justify-content: center;
                .sort {
                    text-align: right;
                }
                .exponential-trend {
                    font-size: 12px;
                    font-weight: normal;
                    margin-left: 5px;
                    min-width: 40px;
                    i {
                        font-size: 12px;
                    }
                }
                .rise-red {
                    color: #FF0000;
                }
                .decline-green {
                    color: #20C997;
                }
            }
            .goods-video-detail {
                display: flex;
                align-items: center;
                .goods-img {
                    width: 100px;
                    height: 130px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .goods-info {
                width: 1%;
                flex: 1;
                margin-left: 15px;
                line-height: 1;
                .goods-link{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    display: block;
                    color: #333;
                    &:hover {
                        color: #0824CE;
                    }
                }
                .related-info {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-top: 10px;
                    .related-link {
                        color: #E6A23C;
                        &:hover{
                            color: #0824CE;
                        }
                    }
                }
                .user-detail {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .fans-num {
                    display: block;
                    color: #999;
                    margin-top: 6px;
                }
            }
            .goods-detail {
                display: flex;
                align-items: center;
                cursor: pointer;
                .goods-img {
                    width: 44px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .borad-name {
                color: #2338E6;
                display: block;
            }
            ::v-deep.el-table__expanded-cell {
                padding: 0;
            }
            ::v-deep.el-table__expanded-cell .el-table th,
            ::v-deep.el-table__expanded-cell .el-table tr {
                background-color: #f3f3f6;
            }
          }
            ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
                background-color: transparent;
            }
            ::v-deep.el-table td{
                padding:7px 0;
            }
            ::v-deep.el-table td {
                border-bottom: 8px solid #ebeef5;
            }
            ::v-deep.el-table th{
                padding: 14px 0 ;
            }
        }

</style>